import { UUIHorizontalPulseKeyframes } from '@umbraco-ui/uui-base/lib/animations';
import { UUIFormControlMixin } from '@umbraco-ui/uui-base/lib/mixins';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { css, LitElement, html, svg, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { UUIEvent } from '@umbraco-ui/uui-base/lib/events';

const nativeInputStyles = css`
  input[type='range'] {
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;

    height: 100%;
    -webkit-appearance: none;
    margin: 0px;
    padding: 0px;
    border: 0 none;
    background: transparent;
    color: transparent;
    overflow: visible;
    border: none;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;
    color: transparent;
    overflow: visible;
    order: none;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: none;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 100%;
    -moz-appearance: none;
    margin: 0px;
    padding: 0px;
    border: 0 none;
    background: transparent;
    color: transparent;
    overflow: visible;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    margin: 0px;
    padding: 0px;
    border: 0 none;
    background: transparent;
    color: transparent;
    overflow: visible;
  }
  input[type='range']::-ms-fill-lower,
  input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0 none;
  }

  input[type='range']::-ms-tooltip {
    display: none;
  }

  input[type='range']::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 12px;
    border: 0 none;
    background: transparent;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    border-radius: 12px;
    border: 0 none;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type='range']::-ms-thumb {
    width: 18px;
    height: 18px;
    border-radius: 12px;
    border: 0 none;
    background: transparent;
    cursor: pointer;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: transparent;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: transparent;
  }
`;

class UUISliderEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUISliderEvent.INPUT = "input";
UUISliderEvent.CHANGE = "change";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __typeError = (msg) => {
  throw TypeError(msg);
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);
var _stepDecimalPlaces, _step;
const TRACK_PADDING = 12;
const STEP_MIN_WIDTH = 24;
const RenderTrackSteps = (steps, stepWidth) => {
  return svg`
  ${steps.map((el) => {
    if (stepWidth >= STEP_MIN_WIDTH) {
      const x = Math.round(TRACK_PADDING + stepWidth * steps.indexOf(el));
      return svg`<circle class="track-step" cx="${x}" cy="50%" r="4.5" />`;
    }
    return svg``;
  })}
`;
};
const RenderStepValues = (steps, stepWidth, hide) => {
  if (hide) return nothing;
  return html`<div id="step-values">
    ${steps.map(
    (el) => html` <span
          ><span>
            ${steps.length <= 20 && stepWidth >= STEP_MIN_WIDTH ? el.toFixed(0) : nothing}
          </span></span
        >`
  )}
  </div>`;
};
const GenerateStepArray = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
let UUISliderElement = class extends UUIFormControlMixin(LitElement, "") {
  constructor() {
    super();
    __privateAdd(this, _stepDecimalPlaces, 0);
    this.hideStepValues = false;
    this.hideValueLabel = false;
    this.min = 0;
    this.max = 100;
    __privateAdd(this, _step, 1);
    this.disabled = false;
    this.readonly = false;
    this._stepWidth = 0;
    this.onWindowResize = () => {
      this._stepWidth = this._calculateStepWidth();
    };
    this._steps = [];
    this._sliderPosition = "0%";
    this.addEventListener("mousedown", () => {
      this.style.setProperty("--uui-show-focus-outline", "0");
    });
    this.addEventListener("blur", () => {
      this.style.setProperty("--uui-show-focus-outline", "");
    });
    this.addEventListener("keypress", this._onKeypress);
  }
  get step() {
    return __privateGet(this, _step);
  }
  set step(value) {
    __privateSet(this, _step, value);
    __privateSet(this, _stepDecimalPlaces, (value.toString().split(".")[1] || []).length);
  }
  get value() {
    return super.value;
  }
  set value(newVal) {
    if (newVal instanceof File) {
      return;
    }
    const oldVal = super.value;
    let correctedValue = newVal ? parseFloat(newVal) : 0;
    correctedValue = Math.min(Math.max(correctedValue, this.min), this.max);
    if (this.step > 0) {
      correctedValue = Math.round(correctedValue / this.step) * this.step;
    }
    super.value = correctedValue.toFixed(__privateGet(this, _stepDecimalPlaces)).toString();
    this._calculateSliderPosition();
    this.requestUpdate("value", oldVal);
  }
  /**
   * This method enables <label for="..."> to focus the select
   */
  async focus() {
    await this.updateComplete;
    this._input.focus();
  }
  async blur() {
    await this.updateComplete;
    this._input.blur();
  }
  /**
   * This method enables <label for="..."> to open the select
   */
  async click() {
    await this.updateComplete;
    this._input.click();
  }
  getFormElement() {
    return this._input;
  }
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("resize", this.onWindowResize);
    if (!this.label) {
      console.warn(this.tagName + " needs a `label`", this);
    }
  }
  disconnectedCallback() {
    window.removeEventListener("resize", this.onWindowResize);
    super.disconnectedCallback();
  }
  firstUpdated() {
    this._calculateSliderPosition();
    this._updateSteps();
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.get("max") || changedProperties.get("min") || changedProperties.get("step")) {
      this.value = this.value;
      this._updateSteps();
    }
  }
  _updateSteps() {
    this._steps = GenerateStepArray(this.min, this.max, this.step);
    this._stepWidth = this._calculateStepWidth();
  }
  _calculateStepWidth() {
    return (this._track.getBoundingClientRect().width - TRACK_PADDING * 2) / (this._steps.length - 1);
  }
  _onKeypress(e) {
    if (e.key == "Enter") {
      this.submit();
    }
  }
  _calculateSliderPosition() {
    const ratio = (parseFloat(super.value || "0") - this.min) / (this.max - this.min);
    this._sliderPosition = `${Math.floor(ratio * 1e5) / 1e3}%`;
  }
  _onInput(e) {
    e.stopPropagation();
    this.value = this._input.value;
    this.dispatchEvent(new UUISliderEvent(UUISliderEvent.INPUT));
  }
  _onChange(e) {
    e.stopPropagation();
    this.pristine = false;
    this.dispatchEvent(new UUISliderEvent(UUISliderEvent.CHANGE));
  }
  render() {
    return html`
      <input
        id="input"
        type="range"
        min="${this.min}"
        max="${this.max}"
        .value="${this.value}"
        aria-label="${this.label}"
        step="${+this.step}"
        ?disabled=${this.disabled || this.readonly}
        ?readonly=${this.readonly}
        @input=${this._onInput}
        @change=${this._onChange} />
      <div id="track" aria-hidden="true">
        <svg height="100%" width="100%">
          <rect x="9" y="9" height="3" rx="2" />
          ${RenderTrackSteps(this._steps, this._stepWidth)}
        </svg>

        <div id="track-inner" aria-hidden="true">
          <div id="thumb" style=${styleMap({ left: this._sliderPosition })}>
            ${this.hideValueLabel ? null : html`<div id="thumb-label">${this.value}</div>`}
          </div>
        </div>
      </div>
      ${RenderStepValues(this._steps, this._stepWidth, this.hideStepValues)}
    `;
  }
};
_stepDecimalPlaces = new WeakMap();
_step = new WeakMap();
/**
 * This is a static class field indicating that the element is can be used inside a native form and participate in its events. It may require a polyfill, check support here https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/attachInternals.  Read more about form controls here https://web.dev/more-capable-form-controls/
 * @type {boolean}
 */
UUISliderElement.formAssociated = true;
UUISliderElement.styles = [
  UUIHorizontalPulseKeyframes,
  nativeInputStyles,
  css`
      :host {
        display: inline-block;
        width: 100%;
        position: relative;
        min-height: 30px;
        user-select: none;
      }

      input {
        box-sizing: border-box;
        height: 18px;
      }

      #track {
        position: relative;
        height: 18px;
        width: 100%;
        display: flex;
      }

      #track svg {
        height: 21px;
        border-radius: 10px;
        background-color: var(--uui-color-surface,#fff);
      }
      #track svg rect {
        width: calc(100% - 18px);
        fill: var(--uui-color-border-standalone,#c2c2c2);
      }
      input:hover ~ #track svg rect {
        fill: var(--uui-color-border-emphasis,#a1a1a1);
      }

      input:focus ~ #track #thumb {
        outline: calc(2px * var(--uui-show-focus-outline, 1)) solid
          var(--uui-color-focus,#3879ff);
      }

      .track-step {
        fill: var(--uui-color-border,#d8d7d9);
      }

      input:hover ~ #track svg .track-step {
        fill: var(--uui-color-border-emphasis,#a1a1a1);
      }

      #track-inner {
        position: absolute;
        left: ${TRACK_PADDING}px; /* Match TRACK_MARGIN */
        right: ${TRACK_PADDING}px; /* Match TRACK_MARGIN */
      }

      #thumb {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 2px;
        bottom: 0;
        left: 0;
        height: 17px;
        width: 17px;
        margin-left: -8px;
        margin-right: -8px;
        border-radius: 50%;
        box-sizing: border-box;

        background-color: var(--uui-color-surface,#fff);
        border: 2px solid var(--uui-color-selected,#3544b1);
      }
      :host([disabled]) #thumb {
        background-color: var(--uui-color-disabled,#f3f3f5);
        border-color: var(--uui-color-disabled-standalone,rgb(226, 226, 226));
      }

      #thumb:after {
        content: '';
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background-color: var(--uui-color-selected,#3544b1);
      }

      :host([disabled]) #thumb:after {
        background-color: var(--uui-color-disabled,#f3f3f5);
      }

      #thumb-label {
        position: absolute;
        box-sizing: border-box;
        font-weight: 700;
        bottom: 15px;
        left: 50%;
        width: 40px;
        margin-left: -20px;
        text-align: center;
        opacity: 0;
        transition: 120ms opacity;
        color: var(--uui-color-selected,#3544b1);
      }

      input:focus ~ #track #thumb-label,
      input:hover ~ #track #thumb-label {
        opacity: 1;
      }

      #step-values {
        margin: 0 ${TRACK_PADDING}px; /* Match TRACK_MARGIN */
        margin-top: 6px;
        display: flex;
        align-items: flex-end;
        box-sizing: border-box;
      }

      #step-values > span {
        flex-basis: 0;
        flex-grow: 1;
        color: var(--uui-color-disabled-contrast,#c4c4c4);
      }

      #step-values > span > span {
        transform: translateX(-50%);
        display: inline-block;
        text-align: center;
        font-size: var(--uui-type-small-size,12px);
      }

      #step-values > span:last-child {
        width: 0;
        flex-grow: 0;
      }

      :host(:not([pristine]):invalid) #thumb {
        border-color: var(--uui-color-danger-standalone,rgb(191, 33, 78));
      }
      :host(:not([pristine]):invalid) #thumb:after {
        background-color: var(--uui-color-danger,#d42054);
      }

      // readonly
      :host([readonly]) #thumb {
        background-color: var(--uui-color-disabled,#f3f3f5);
        border-color: var(--uui-color-disabled-standalone,rgb(226, 226, 226));
      }

      :host([readonly]) #thumb-label {
        opacity: 1;
      }
    `
];
__decorateClass([
  property({ type: Boolean, attribute: "hide-step-values" })
], UUISliderElement.prototype, "hideStepValues", 2);
__decorateClass([
  property({ type: Boolean, attribute: "hide-value-label" })
], UUISliderElement.prototype, "hideValueLabel", 2);
__decorateClass([
  property({ type: Number })
], UUISliderElement.prototype, "min", 2);
__decorateClass([
  property({ type: Number })
], UUISliderElement.prototype, "max", 2);
__decorateClass([
  property({ type: Number })
], UUISliderElement.prototype, "step", 1);
__decorateClass([
  property({ type: String })
], UUISliderElement.prototype, "value", 1);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUISliderElement.prototype, "disabled", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUISliderElement.prototype, "readonly", 2);
__decorateClass([
  property({ type: String })
], UUISliderElement.prototype, "label", 2);
__decorateClass([
  query("#input")
], UUISliderElement.prototype, "_input", 2);
__decorateClass([
  query("#track")
], UUISliderElement.prototype, "_track", 2);
__decorateClass([
  state()
], UUISliderElement.prototype, "_stepWidth", 2);
__decorateClass([
  state()
], UUISliderElement.prototype, "_steps", 2);
__decorateClass([
  state()
], UUISliderElement.prototype, "_sliderPosition", 2);
UUISliderElement = __decorateClass([
  defineElement("uui-slider")
], UUISliderElement);

export { UUISliderElement, UUISliderEvent };
