import { UUIEvent, UUISelectableEvent } from '@umbraco-ui/uui-base/lib/events';
import { defineElement } from '@umbraco-ui/uui-base/lib/registration';
import { LitElement, html, css } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { LabelMixin } from '@umbraco-ui/uui-base/lib/mixins';

class UUIColorSwatchesEvent extends UUIEvent {
  constructor(evName, eventInit = {}) {
    super(evName, {
      ...{ bubbles: true },
      ...eventInit
    });
  }
}
UUIColorSwatchesEvent.CHANGE = "change";

var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
let UUIColorSwatchesElement = class extends LabelMixin("label", LitElement) {
  constructor() {
    super();
    this.value = "";
    this.disabled = false;
    this.readonly = false;
    this._onSelected = (event) => {
      const target = event.target;
      if (!this._swatches.includes(target)) return;
      if (this._selectedElement) {
        this._selectedElement.selected = false;
        this._selectedElement.active = false;
        this._selectedElement = void 0;
      }
      this._selectedElement = target;
      this._activeElement = this._selectedElement;
      this.value = this._selectedElement.value || "";
      this.dispatchEvent(new UUIColorSwatchesEvent(UUIColorSwatchesEvent.CHANGE));
    };
    this._onDeselected = (event) => {
      const target = event.target;
      if (!this._swatches.includes(target)) return;
      if (this._activeElement === target) {
        this._activeElement = void 0;
      }
      if (this._selectedElement === target) {
        this._selectedElement.selected = false;
        this._selectedElement.active = false;
        this._selectedElement = void 0;
        this.value = "";
        this.dispatchEvent(
          new UUIColorSwatchesEvent(UUIColorSwatchesEvent.CHANGE)
        );
      }
    };
    this.addEventListener(UUISelectableEvent.SELECTED, this._onSelected);
    this.addEventListener(UUISelectableEvent.DESELECTED, this._onDeselected);
  }
  get _activeElement() {
    return this.__activeElement;
  }
  set _activeElement(el) {
    if (this.__activeElement) {
      this.__activeElement.active = false;
    }
    if (el) {
      el.active = true;
      this.__activeElement = el;
    }
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute("role", "radiogroup");
    this.setAttribute("aria-label", this.label);
  }
  willUpdate(_changedProperties) {
    if (_changedProperties.has("label")) {
      this.setAttribute("aria-label", this.label);
    }
  }
  _handleSlotChange() {
    if (!this._swatches || this._swatches.length === 0) return;
    this._swatches.forEach((swatch) => {
      swatch.setAttribute("aria-checked", "false");
      swatch.setAttribute("role", "radio");
      if (this.disabled) {
        swatch.setAttribute("disabled", "");
      } else {
        swatch.setAttribute("selectable", "selectable");
      }
      if (this.readonly) {
        swatch.setAttribute("readonly", "");
      }
      if (this.value !== "" && swatch.value === this.value) {
        swatch.selected = true;
        swatch.setAttribute("aria-checked", "true");
        this._selectedElement = swatch;
        this._activeElement = this._selectedElement;
      }
    });
  }
  /**
   * Deselects all swatches.
   *
   * @memberof UUIColorSwatchesElement
   */
  resetSelection() {
    this._swatches.forEach((swatch) => {
      swatch.selected = false;
      swatch.active = false;
      swatch.selectable = !swatch.disabled;
    });
    this._activeElement = void 0;
    this._selectedElement = void 0;
    this.value = "";
  }
  render() {
    return html`<slot @slotchange=${this._handleSlotChange}></slot>`;
  }
};
UUIColorSwatchesElement.styles = [
  css`
      :host {
        display: flex;
        flex-wrap: wrap;
        gap: 0.4rem;
      }
    `
];
__decorateClass([
  property()
], UUIColorSwatchesElement.prototype, "value", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIColorSwatchesElement.prototype, "disabled", 2);
__decorateClass([
  property({ type: Boolean, reflect: true })
], UUIColorSwatchesElement.prototype, "readonly", 2);
__decorateClass([
  queryAssignedElements({ selector: "uui-color-swatch" })
], UUIColorSwatchesElement.prototype, "_swatches", 2);
UUIColorSwatchesElement = __decorateClass([
  defineElement("uui-color-swatches")
], UUIColorSwatchesElement);

export { UUIColorSwatchesElement, UUIColorSwatchesEvent };
